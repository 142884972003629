import Image from "next/image"

import Link from "next/link"
import Container from "../Containers/Container"

const Footer: React.FC = () => {
  return (
    <footer className='mt-auto py-52 px-0 bg-main'>
      <Container className='grid grid-cols-4 gap-y-10 '>
        <div className='flex flex-col'>
          <Link href='#' className='w-16 h-16 mb-12'>
            <Image
              alt='SzkolnyPrzewodnik logo'
              src='/Logo.png'
              width={100}
              height={100}
            />
          </Link>

          <ul className='text-white16 list-none flex gap-5 items-center justify-center w-1/2'>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='https://www.instagram.com/szkolnyprzewodnik'>
                <Image
                  className='select-none size-7 '
                  src='/social/instagram-icon.png'
                  alt='instagram'
                  width={100}
                  height={100}
                />
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='https://www.tiktok.com/@szkolnyprzewodnik'>
                <Image
                  className='select-none size-7'
                  src='/social/tiktok-icon.png'
                  alt='tiktok'
                  width={100}
                  height={100}
                />
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='https://discord.gg/FGQCnegPht'>
                <Image
                  className='select-none size-7'
                  src='/social/discord-icon.png'
                  alt='discord'
                  width={100}
                  height={100}
                />
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='https://www.facebook.com/szkolnyprzewodnik'>
                <Image
                  className='select-none size-7'
                  src='/social/facebook-icon.png'
                  alt='facebook'
                  width={100}
                  height={100}
                />
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='https://www.youtube.com/@SzkolnyPrzewodnik'>
                <Image
                  className='select-none size-7'
                  src='/social/youtube-icon.png'
                  alt='youtube'
                  width={100}
                  height={100}
                />
              </Link>
            </li>
          </ul>
          <a href='https://zwolnienizteorii.pl/'>
            <Image
              className='my-10 flex items-center w-6/12 h-auto hover:cursor-pointer'
              role='link'
              src={"/zwzt.png"}
              alt={"Realizowane w ramach zwzt"}
              width={250}
              height={75}
            />
          </a>

          <p className='text-xl leading-[1.6] text-white48 mt-auto'>
            Copyright &copy; 2024 SzkolnyPrzewodnik.
            <br />
            Wszelkie prawa zastrzeżone
          </p>
        </div>

        <nav>
          <p className='text-3xl font-medium mb-16 text-white16'>
            Linki Nawigacyjne
          </p>
          <ul className='list-none flex flex-col gap-10'>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/'>
                Strona Główna
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/about'>
                O nas
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/contact'>
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>

        <nav>
          <p className='text-3xl font-medium mb-16 text-white16'>Więcej</p>
          <ul className='list-none flex flex-col gap-10'>
            <li>
              <a
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/app/kalkulator'>
                Kalkulator
              </a>
            </li>
            <li>
              <a
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/app/doradztwo'>
                Doradztwo
              </a>
            </li>
            <li>
              <a
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/app/'>
                Notatki
              </a>
            </li>
            <li>
              <a
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/app/kalendarz'>
                Kalendarz
              </a>
            </li>
          </ul>
        </nav>

        <nav>
          <p className='text-3xl font-medium mb-16 text-white16'>Dokumenty</p>
          <ul className='list-none flex flex-col gap-10'>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/docs/polityka-prywatnosci.pdf'>
                Polityka Prywatności
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/docs/polityka-cookies.pdf'>
                Polityka Cookies
              </Link>
            </li>
            <li>
              <Link
                className='no-underline text-2xl text-[#bbb] transition-all duration-300 hover:text-[#555s]'
                href='/docs/regulamin.pdf'>
                Regulamin
              </Link>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  )
}

export default Footer

/*
Linki do stron: Home, About, Contact, Więcej
Informacje kontaktowe
Linki do mediów społecznościowych



*/
